<template>
  <div>
    <AppTable :rows="rows" :columns="columns" :is-loading="requestInProgress" :is-pagination="false">
      <template #default="{ column, row, formattedRow }">
        <span v-if="column.field === 'action'" class="d-flex">
          <b-button class="mt-auto text-white ml-1" type="button" variant="primary" @click="edit(row)">
            <span class="text-white"> Edit </span>
          </b-button>
        </span>

        <span v-else class="flex-center-align">
          {{ formattedRow[column.field] }}
        </span>
      </template>
    </AppTable>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters } from 'vuex'

import waitRequest from '@/mixins/waitRequest'
import validationError from '@/mixins/validationError'
import doCopy from '@/mixins/doCopy'

import AppTable from '@/components/AppTable.vue'
import columns from './config/tableConfig'

export default {
  name: 'FeeView',
  components: {
    AppTable,
    BButton,
  },
  directives: {
    Ripple,
  },
  mixins: [waitRequest, validationError, doCopy],
  data() {
    return {
      columns,
      searchTerm: '',
    }
  },

  computed: {
    ...mapGetters({
      fee: 'cefiFees/fee',
    }),

    rows() {
      return this.transformData(this.fee.value || [])
    },
  },
  mounted() {
    this.initState()
  },

  methods: {
    ...mapActions({
      fetchFee: 'cefiFees/fetchFee',
    }),

    initState() {
      this.getFee()
    },

    getFee() {
      return this.waitRequest(() => {
        return this.fetchFee().catch(this.checkErrors)
      })
    },

    edit(row) {
      this.$router.push({ path: `/fee/edit/${row.id}` })
    },

    transformData(rows) {
      return rows.map(row => {
        const feeCurrency = `${row.feeCoin?.code || ''} ${row.feeCoin?.symbol || ''}`

        const { code = '', symbol = '', network = '' } = row.cryptoCoin ?? {}
        const cryptoCoin = `${code || ''} ${symbol || ''} ${network ? '/ ' + network : ''}`
        
        return {
          ...row,
          feeCurrency,
          cryptoCoin,
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-good-table.scss';
</style>
